.gridmargin {
	margin: 10px 0px 10px 0px;
}
  .selectmedialanguage{

    width: 20vw;
    margin: 0;

  }

  .selectlanguagedata{
    margin: 0px 0px 0px 0px;
    width: 20vw;
    margin: 0;
  }

  #myImage {
    opacity: 0.5;
    filter: alpha(opacity=50); /* msie */
}

.descriptionBlock{
  border-radius: 25px;
  border: 1px solid grey;
  padding: 10px;
  margin: 5px;

  }
