.ellips {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.divertical {
  height: 28px;
  margin: 4px;
}

.pad10 {
  padding: 10px;
}

.top20 {
  padding-top: 20px;
}

.bottom20{
  padding-bottom: 20px;
}

.bottom56 {
  padding-bottom: 56px;
}

.flexPad {
  padding: 2px 4px;
  display: flex;
  alignItems: center;
}

.flexInput {
  margin-left: 8px;
  flex: 1;
}

/* .MuiFormLabel-root {
padding-bottom: 16px!important;
color: "red"!important;
} */

/* .MuiTextField-root > label {
background-color: orange;
color: red;
} */

.MuiTextField-root > .MuiFormLabel-root.Mui-focused {
  padding-top: 8px;
}
