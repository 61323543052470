.mediacontent {
  width: 45%;
  object-fit: contain;
  cursor: pointer;
}

.info-button {
  /* text-align: center; */
  /* margin: 7%; */
  /* width: 350px; */
  /* position: relative;  */
}

.info-button:hover .info-overlay {
  -webkit-transform: scale(1);
}

.info-overlay {
  position: absolute;
  width: 250px;
  height: 95%;
  overflow-y: auto;
  /* line-height: 284px; */
  /* top: 5px; */
  /* color: white; */
  /* font-family: sans-serif; */
  /* font-size: 20px; */
  /* font-weight: bold; */
  /* left: 6px; */
  /* text-align: center; */
  transition: all 0.2s ease-in-out;
  -webkit-transform: scale(0);
  border: 1px solid white;
  background-color: #ffffffb0;
  /* border-radius: 15px; */
  padding: 8px;
  box-shadow: 9px 9px 16px rgba(189, 189, 189, 0.6), -9px -9px 16px rgba(255, 255, 255, 0.5);
  word-wrap: break-word;
  display: inline-flex;
}

.info-sub {
  text-align: center;
  position: absolute;
  top: -7px;
  z-index: 3;
  /* font-size: 24px; */
  /* font-weight: bold; */
  /* background: rgba(36, 18, 2, 0.65); */
  /* color: white; */
  width: 95%;
}

.rights-img {
	width: 70px;
	bottom: 6px;
	margin-left: 10px;
	position: relative;
}

.fsize25 {
  font-size: 2.5rem!important;
}

.qrcode {
  object-fit: contain;
  margin: 232px;
  display: block;
  position: absolute;
  display: relative;
  margin-left: 30%;
  margin-right: auto;
  margin-top: 5%;
  width: 40%;
}

.document-div2{
  /* position: absolute; */
  width: 100%;
  height: 100%;
  margin: auto;
  overflow: scroll
}

.document-div-inner{
  margin-top: 100px;
  margin-left : 100px;
  margin-right : 100px;
  text-align:center;
  position:relative;


}

.positioncloud{
  bottom: 15px;
  color:black;
}


.fsize35{
  font-size: 3.5rem!important;
}

.rights-img-rm{
  width: 100px;

}

.gridmargin{
  margin: 10px 10px 10px 0px;
}



.box-wrap{
  display:flex!important;
  align-items:center;
  justify-content:center;
  margin:25px;
}
/* .box{
  padding:5px;
  margin:2%;
  background:white;
  width:30%;
  border:1px solid #d6d6d6;
  box-shadow:0 2px 3px 0px rgba(0,0,0,0.25);
  border-radius:3px;
  transition:.2s all;
} */
.box {
	/* padding: 10px; */
	margin: -20px auto -20px auto;
	/* background: white; */
	width: 25%;
	border: 1px solid #d6d6d6;
	box-shadow: 0 2px 3px 0px rgba(0,0,0,0.25);
	border-radius: 3px;
	transition: .2s all;
}
.box-wrap:hover .box{
  filter:blur(3px);
  opacity:.5;
  transform: scale(.98);
  box-shadow:none;
}
.box-wrap:hover .box:hover{
  transform:scale(1);
  filter:blur(0px);
  opacity:1;
  box-shadow:0 8px 20px 0px rgba(0,0,0,0.125);
}

div.ellipsed {
  white-space: nowrap;
  width: 99%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 15px
}

.ellipsedTitle {
  white-space: nowrap;
  width: 99%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MuiDivider-root-353 {
	background-color: rgba(0, 0, 0, 0.04)!important;
}

.pad-05 {
	padding:2px;
}

.pad-5 {
	padding:5px;
}

.fsize15 {
  font-size: 2.2rem!important;
}

.prev-bright {
  text-align:right;
  font-weight: 900
}

.prev-bleft {
  text-align:left;
  /* font-weight: 900 */
}
