.cardmap {
    /* Add shadows to create the "card" effect */
   width: 100%;
   padding: 2px 5px;
   overflow: hidden;
   height: 100%;
   color: #000;
}


.cardmap img {

   width: 18px;
   height: 18px;
}


.mapbutton{
     display: block;
     width: 100%;
     border: none;
     background-color: #5a6199;
     color: white;
     padding: 14px 28px;
     font-size: 16px;
     cursor: pointer;
     text-align: center;
}

 .mapbutton:hover{
    background-color: #ddd;
    color: black;
 }
