.selectright{
  margin: 0px 0px 0px 0px;
  width: 20vw;
  margin: 0;
  display:inline-block;

}
.selectmedialanguage{

  width: 20vw;
  margin: 0;

}

.selectlanguagedata{
  margin: 0px 0px 0px 0px;
  width: 20vw;
  margin: 0;
}

#myImage {
  opacity: 0.5;
  filter: alpha(opacity=50); /* msie */
}

.descriptionBlock{
  border-radius: 25px;
  border: 1px solid grey;
  padding: 10px;
  margin: 5px;

}
.selectright1{
  margin: 0px 0px 0px 0px;
  width: 9vw;
  margin: 0;
  display:inline-block;
}
.gridmargin2{
  margin: 10px 0px 10px 0px;
}
