/* .focus-only:focus {
outline: 1px solid black;
}

.focus-visible-only:focus-visible {
outline: 4px dashed darkorange;
} */


.fontnormal {
  font-size: 100%;
}

.fontlarge {
  font-size: 107%;
}

.fontxlarge {
  font-size: 114%;
}

.fontxxlarge {
  font-size: 120%;
}

/* ===== MENU HEADER ====== */

.logo {
  /* margin: 5px 0px 0px 0px; */
  object-fit: contain;
  /* height: 5vh; */
  height: 55px;
}

@import url(https://fonts.googleapis.com/css?family=Raleway:400,500);
.head-animation {
  font-family: 'Raleway', Arial, sans-serif;
  text-align: left;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 0.1em;
}
.head-animation * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.head-animation li {
  display: inline-block;
  list-style: outside none none;
  margin: 0 2em;
  padding: 0;
}
.head-animation a {
  display: inline-block;
  padding: 0.1em 17px;
  /* color: limoncello; */
  position: relative;
  letter-spacing: 1px;
  text-decoration: none;
}
.head-animation a:before {
  left: 20%;
  right: 20%;
  top: 50%;
  content: '';
  border-left: 12px solid #4791db;
  border-right: 12px solid #4791db;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 3px;
  opacity: 0;
  position: absolute;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.head-animation a:hover,
.head-animation .current a {
  color: #4791db;
}
.head-animation a:hover:before,
.head-animation .current a:before {
  left: 0;
  right: 0;
  opacity: 1;
}



/* ===== IFRAME MENU SUB-HEADER ====== */


@import url(https://fonts.googleapis.com/css?family=Raleway:400,500);
.iframe-animation {
  font-family: 'Raleway', Arial, sans-serif;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 0.1em;
}
.iframe-animation * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.iframe-animation li {
  display: inline-block;
  list-style: outside none none;
  margin: 0 1em;
  padding: 0;
}
.iframe-animation a {
  padding: 0 0.8em;
  /* margin: 0.2em 0; */
  display: block;
  /* color: rgba(255, 255, 255, 0.5); */
  position: relative;
  text-decoration: none;
}
.iframe-animation a:before,
.iframe-animation a:after {
  height: 14px;
  width: 14px;
  position: absolute;
  content: '';
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  opacity: 0;
}
.iframe-animation a:before {
  left: 0;
  top: 0;
  border-left: 3px solid #ffffff;
  border-top: 3px solid #ffffff;
  -webkit-transform: translate(100%, 50%);
  transform: translate(100%, 50%);
}
.iframe-animation a:after {
  right: 0;
  bottom: 0;
  border-right: 3px solid #ffffff;
  border-bottom: 3px solid #ffffff;
  -webkit-transform: translate(-100%, -50%);
  transform: translate(-100%, -50%);
}
.iframe-animation a:hover,
.iframe-animation .current a {
  /* color: #ffffff; */
}
.iframe-animation a:hover:before,
.iframe-animation .current a:before,
.iframe-animation a:hover:after,
.iframe-animation .current a:after {
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  opacity: 1;
}

.avatarSh {
  width:40px;
  height:40px;
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12);
}

.info {
  padding:10px;
  width: auto;
}

.info-button {
  padding: 25px 0px 0px 0px;
  text-align: start;
}

.container-info {
  display: flex;
}
.container-info > div {
  flex: 1; /*grow*/
}


.left-column
{
  float:left;
  width:35%;
}

.right-column
{
  float:right;
  width:65%;
}

/*
.center-column
{
margin:auto;
width:30%;
}
*/

.cultural-space {
  width:100%;
  display: inline-flex;
}

.bold {
  font-weight: 900!important;
}

.left-close {
  margin: -5px 0 -30px 88%;
}
