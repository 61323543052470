
.resourcesbody {
    margin-top: 50px;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-left: 10px;
}

.paperdivres{
 padding: 3px 3px;
 display: flex;
 width: 300;
}

.dividerinputres {
     height: 20;
     margin: 4;
}