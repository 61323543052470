/* .iframe {
    margin-top: 140px;
    position: absolute;
    width: 100%;
    height: auto;
} */

.textarea {
  width: 100%;
}

.paddingTop {
  padding-top: 20px!important;
}

.paddingContainer {
  padding-top: 10px;
  padding-bottom: 20px;
}

.paddingLeft {
  padding-left: 5px;
}

.typogr-width {
  width: 95px;
}

.select-lang {
  padding-top: 20px;
  width: 80%;
}

.typogr-logo {
  width: 150px;
}
